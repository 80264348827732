export default defineNuxtRouteMiddleware(async (to) => {
  const invitationId = to.query.invitationId;
  const { $i18n } = useNuxtApp();

  if (!invitationId) return;

  const { error } = await useLazyFetch(`/api/join/${invitationId}`, {
    key: `invitation_${invitationId}`,
    deep: false,
  });

  if (error.value)
    return abortNavigation({
      statusCode: 404,
      statusMessage: $i18n.global.t("organization_invitation.error_not_found"),
    });
});
